export class Evento {
  constructor({
    id,
    codigo,
    descricao,
    tipo_evento,
    tipo_calculo,
    tipo_variacao,
    valor_evento,
    tipo_vigencia,
    meses_vigencia,
    tipo_ocorrencia,
    tipo_lancamento,
    evento_ocorrencia_id,
    tipo_agregate,
    evento_agregate_id,
    evento_transf_id,
    tipo_variacao_transf,
    percent_transf,
    status,
  } = {
      id: '',
      codigo: '',
      descricao: '',
      tipo_evento: '0',
      tipo_calculo: '0',
      tipo_variacao: '0',
      valor_evento: 0,
      tipo_vigencia: '0',
      tipo_lancamento: '0',
      meses_vigencia: [],
      tipo_ocorrencia: '0',
      evento_ocorrencia_id: '',
      tipo_agregate: '0',
      evento_agregate_id: '',
      evento_transf_id: '',
      tipo_variacao_transf: '0',
      percent_transf: 0,
      status: 0,
    }) {
    this.id = id
    this.codigo = codigo
    this.descricao = descricao
    this.tipo_evento = tipo_evento
    this.tipo_calculo = tipo_calculo
    this.tipo_variacao = tipo_variacao
    this.tipo_vigencia = tipo_vigencia
    this.meses_vigencia = meses_vigencia
    this.tipo_ocorrencia = tipo_ocorrencia
    this.evento_ocorrencia_id = evento_ocorrencia_id
    this.tipo_agregate = tipo_agregate
    this.tipo_lancamento = tipo_lancamento
    this.evento_agregate_id = evento_agregate_id
    this.evento_transf_id = evento_transf_id
    this.percent_transf = percent_transf
    this.tipo_variacao_transf = tipo_variacao_transf
    this.valor_evento = valor_evento || 0.00
    this.status = status
  }
}

export function createEvento (data) {
  return Object.freeze(new Evento(data))
}
