<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Identificação do Evento">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                ref="codigo"
                v-model="codigo"
                v-mask="maskCodigo"
                dense
                type="string"
                autocomplete="off"
                label="Código*"
                :disabled="isEditing"
                :rules="[rules.required,validaCodigo]"
                validate-on-blur
                outlined
                rounded
                maxlength="7"
                :error-messages="errors"
                @blur="validaGrupo"
                @click="limpaErro"
              />
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <v-text-field
                v-model="descricao"
                dense
                outlined
                rounded
                autocomplete="off"
                label="Descrição*"
                validate-on-blur
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </crud-card>
        <crud-card
          title="Parâmetros de Cálculo"
        >
          <v-row>
            <v-col
              cols="6"
              md="2"
            >
              <v-radio-group
                v-model="tipo_evento"
                :disabled="codigo === ''"
                @change="checaTipo"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Evento</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong class="primary--text">Crédito</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong class="error--text">Débito</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="eventoTitulo"
              cols="6"
              md="2"
            >
              <v-radio-group
                v-model="tipo_lancamento"
                @change="limpaCampo"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Lançamento</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Autómatico</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Manual</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="eventoTitulo"
              cols="6"
              md="2"
            >
              <v-radio-group
                v-model="tipo_calculo"
                @change="limpaCampo"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Cálculo</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Percentual</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Valor</strong></div>
                  </template>
                </v-radio>
                <v-radio
                  v-if="tipo_lancamento !== '1'"
                  value="2"
                >
                  <template v-slot:label>
                    <div><strong>Transferência</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="liberaVariacao"
              cols="6"
              md="3"
            >
              <v-radio-group
                v-model="tipo_variacao"
                @change="limpaCampo"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Variação</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Fixo no evento</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Variável por Pessoa</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="liberaVariacao"
              class="pt-0"
              cols="12"
              md="3"
            >
              <base-vuetify-money
                v-if="(tipo_calculo === '0' && tipo_variacao === '0')"
                v-model="valor_evento"
                class="pt-2 mb-4"
                :options="percent"
                maxlength="8"
                label="Percentual*"
                validate-on-blur
                :rules="[rules.required]"
                dense
                outlined
                rounded
                hide-details
              />
              <base-vuetify-money
                v-if="(tipo_calculo === '1' && tipo_variacao === '0')"
                v-model="valor_evento"
                class="pt-2 mb-4"
                :options="money"
                maxlength="18"
                label="Valor*"
                validate-on-blur
                :rules="[rules.required]"
                dense
                outlined
                rounded
                hide-details
              />
            </v-col>
          </v-row>
        </crud-card>
        <crud-card
          v-if="eventoTitulo"
          title="Condicionais de Ocorrência"
        >
          <v-row>
            <v-col
              class="pt-0"
              cols="12"
              md="6"
            >
              <v-radio-group
                v-model="tipo_ocorrencia"
                :disabled="codigo === ''"
                @change="limpaOcorrencia"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Ocorrência</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Normal</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Somente uma vez no período</strong></div>
                  </template>
                </v-radio>
                <v-radio value="2">
                  <template v-slot:label>
                    <div><strong>Somente uma vez no período caso seja calculado o evento:</strong></div>
                  </template>
                </v-radio>
                <v-radio value="3">
                  <template v-slot:label>
                    <div><strong>Todas as vezes no período caso seja calculado o evento:</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="8"
              style="align-self: flex-end;"
            >
              <v-autocomplete
                v-if="tipo_ocorrencia === '2' || tipo_ocorrencia === '3'"
                v-model="evento_ocorrencia_id"
                :items="eventosDisponiveis()"
                dense
                outlined
                rounded
                label="Evento de disparo da Ocorrência"
                item-text="descricao"
                item-value="id"
                no-data-text="Não há Eventos disponíveis"
                clearable
                :rules="[rules.required]"
                autocomplete="off"
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-list-item-content
                    class="overflow-y-auto"
                  >
                    <v-list-item-title>{{ data.item.codigo + ' - ' + data.item.descricao }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </crud-card>
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            lg="6"
          >
            <crud-card
              v-if="eventoTitulo"
              title="Condicionais de Período"
              style="min-height: 173px;"
            >
              <v-row>
                <v-col
                  class="pt-0 pb-0"
                  cols="12"
                >
                  <v-radio-group
                    v-model="tipo_vigencia"
                    :disabled="disableVigencia"
                    @change="limpaMeses"
                  >
                    <template v-slot:label>
                      <div><strong>Tipo de Vigência</strong></div>
                    </template>
                    <v-radio value="0">
                      <template v-slot:label>
                        <div><strong>Nenhuma</strong></div>
                      </template>
                    </v-radio>
                    <v-radio value="1">
                      <template v-slot:label>
                        <div><strong>Meses</strong></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  v-if="tipo_vigencia === '1'"
                  class="pl-0 pt-0"
                  cols="6"
                  md="3"
                >
                  <v-container
                    fluid
                    style="padding-top: 0px;"
                  >
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Janeiro"
                      value="1"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Fevereiro"
                      value="2"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Março"
                      value="3"
                      hide-details
                    />
                  </v-container>
                </v-col>
                <v-col
                  v-if="tipo_vigencia === '1'"
                  class="pl-0 pt-0"
                  cols="6"
                  md="3"
                >
                  <v-container
                    fluid
                    style="padding-top: 0px;"
                  >
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Abril"
                      value="4"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Maio"
                      value="5"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Junho"
                      value="6"
                      hide-details
                    />
                  </v-container>
                </v-col>
                <v-col
                  v-if="tipo_vigencia === '1'"
                  class="pl-0 pt-0"
                  cols="6"
                  md="3"
                >
                  <v-container
                    fluid
                    style="padding-top: 0px;"
                  >
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Julho"
                      value="7"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Agosto"
                      value="8"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Setembro"
                      value="9"
                      hide-details
                    />
                  </v-container>
                </v-col>
                <v-col
                  v-if="tipo_vigencia === '1'"
                  class="pl-0 pt-0"
                  cols="6"
                  md="3"
                >
                  <v-container
                    fluid
                    style="padding-top: 0px;"
                  >
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Outubro"
                      value="10"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Novembro"
                      value="11"
                      hide-details
                    />
                    <v-checkbox
                      v-model="meses_vigencia"
                      label="Dezembro"
                      value="12"
                      hide-details
                    />
                  </v-container>
                </v-col>
              </v-row>
            </crud-card>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.mdAndDown ? 'py-0 pr-3' : 'pa-0 py-0 pr-3'"
            cols="12"
            lg="6"
          >
            <crud-card
              v-if="eventoTitulo"
              title="Parâmetros de Agregação no Extrato"
              class="pr-3"
              style="min-height: 173px;"
            >
              <v-row>
                <v-col
                  class="pt-0"
                  cols="4"
                >
                  <div style="width: 180px">
                    <v-radio-group
                      v-model="tipo_agregate"
                      @change="limpaAgregacao"
                    >
                      <template v-slot:label>
                        <div><strong>Tipo de Agregação</strong></div>
                      </template>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div><strong>Nenhuma</strong></div>
                        </template>
                      </v-radio>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div><strong>Agregar a outro evento</strong></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col
                  :class="$vuetify.breakpoint.lgAndUp ? 'pr-0 ml-2' : 'pr-6'"
                  cols="12"
                  lg="7"
                  style="align-self: flex-end;"
                >
                  <v-autocomplete
                    v-if="tipo_agregate === '1'"
                    v-model="evento_agregate_id"
                    :items="eventosDisponiveis()"
                    dense
                    outlined
                    rounded
                    label="Evento para qual deve ser feito a agregação"
                    item-text="descricao"
                    item-value="id"
                    no-data-text="Não há Eventos disponíveis"
                    clearable
                    :rules="[rules.required]"
                    autocomplete="off"
                  >
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-list-item-content
                        class="overflow-y-auto"
                      >
                        <v-list-item-title>{{ data.item.codigo + ' - ' + data.item.descricao }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </crud-card>
          </v-col>
        </v-row>
        <crud-card
          v-if="tipo_calculo === '2'"
          title="Parâmetros de Transferência"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-radio-group
                v-model="tipo_variacao_transf"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Variação</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Fixo no evento</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Variável por Pessoa</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-row>
                <v-autocomplete
                  v-model="evento_transf_id"
                  :items="eventosDisponiveis()"
                  dense
                  outlined
                  rounded
                  label="Evento a ser Transferido"
                  item-text="descricao"
                  item-value="id"
                  no-data-text="Não há Eventos disponíveis"
                  clearable
                  :rules="[rules.required]"
                  autocomplete="off"
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-list-item-content
                      class="overflow-y-auto"
                    >
                      <v-list-item-title>{{ data.item.codigo + ' - ' + data.item.descricao }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row>
                <base-vuetify-money
                  v-if="tipo_variacao_transf === '0'"
                  v-model="percent_transf"
                  :options="percent"
                  maxlength="8"
                  label="Percentual*"
                  validate-on-blur
                  :rules="[rules.required]"
                  dense
                  outlined
                  rounded
                  hide-details
                  style="margin-bottom: 30px; width: 100%;"
                />
              </v-row>
            </v-col>
          </v-row>
        </crud-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="mr-3"
            color="error"
            style="margin-top: 10px;"
            rounded
            @click="
              $router.push({ path: `/${$user.get().role}/eventos` })
            "
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            rounded
            style="margin-top: 10px;"
            @click="cadastrarevento"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import eventoFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/evento'
  import { mapState, mapActions } from 'vuex'
  import api from '@/api/api'
  export default {
    data () {
      return {
        valid: false,
        loadedData: false,
        alertCodigo: false,
        maskCodigo: '###.####',
        rules: rules,
        snackbar: { message: '', show: false },
        percent: {
          locale: 'pt-BR',
          precision: 4,
          prefix: '%',
        },
        money: {
          locale: 'pt-BR',
          precision: 2,
          prefix: 'R$',
        },
      }
    },
    computed: {
      ...mapState('form/evento', ['loading', 'roles', 'isEditing']),
      ...mapState('eventos', ['loadeventos']),
      ...mapFields('form', [
        'data.codigo',
        'data.descricao',
        'data.tipo_evento',
        'data.tipo_calculo',
        'data.tipo_variacao',
        'data.tipo_vigencia',
        'data.valor_evento',
        'data.evento_transf_id',
        'data.percent_transf',
        'data.meses_vigencia',
        'data.tipo_ocorrencia',
        'data.evento_ocorrencia_id',
        'data.tipo_agregate',
        'data.tipo_lancamento',
        'data.evento_agregate_id',
        'data.tipo_variacao_transf',
      ]),
      $disabledFields () {
        if (this.loaded || this.isEditing) {
          return 'disabled'
        }
        return null
      },
      disableVigencia () {
        if (this.codigo === '') {
          return true
        }
        if (this.tipo_ocorrencia !== '0') {
          return true
        }
        return false
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
      eventoTitulo () {
        if ((parseInt(this.codigo.substring(4, 7)) === 0 || this.codigo.substring(4, 7) === '')) {
          return false
        }
        return true
      },
      liberaCampos () {
        if ((parseInt(this.codigo.substring(4, 7)) === 0 || this.codigo.substring(4, 7) === '') || this.tipo_calculo === '2') {
          return false
        }
        return true
      },
      liberaVariacao () {
        if ((parseInt(this.codigo.substring(4, 7)) === 0 || this.codigo.substring(4, 7) === '') || (this.tipo_lancamento === '1' && this.tipo_calculo === '1') || this.tipo_calculo === '2') {
          return false
        }
        return true
      },
      errors () {
        const errors = [];
        (this.alertCodigo) && errors.push('Crie evento título')
        return errors
      },
    },
    created () {
      if (!this.$store.state.form.evento) { this.$store.registerModule(['form', 'evento'], eventoFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/evento', [BOOTSTRAP_PROFILE, SUBMIT]),
      cadastrarevento () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
      validaCodigo (codigo) {
        let retorno = true
        if (codigo.length < 7) {
          retorno = 'Código inválido'
        } else if (parseInt(codigo.substring(0, 3)) <= 0) {
          retorno = 'Código inválido'
        }
        return retorno
      },
      async checaTipo () {
        const codigoTitulo = this.codigo.substring(0, 3) + '.000'
        if (parseInt(codigoTitulo) !== 0) {
          const respostaApi = await api.getEntidade('evento/codigo', codigoTitulo)
          if (respostaApi.data[0][0].tipo_evento !== parseInt(this.tipo_evento)) {
            this.snackbar = {
              show: true,
              message: 'Este evento não pode ter tipo diferente do Evento Titulo: ' + codigoTitulo + ' ' + respostaApi.data[0][0].descricao,
            }
            this.tipo_evento = respostaApi.data[0][0].tipo_evento.toString()
          }
        }
      },
      limpaOcorrencia () {
        if (this.tipo_ocorrencia === '0') {
          this.tipo_vigencia = '0'
          this.meses_vigencia = []
          this.evento_ocorrencia_id = null
        } else if (this.tipo_ocorrencia === '1') {
          this.tipo_vigencia = '1'
          this.evento_ocorrencia_id = null
        } else {
          this.tipo_vigencia = '1'
        }
      },
      limpaAgregacao () {
        if (this.tipo_agregate === '0') {
          this.evento_agregate_id = null
        }
      },
      limpaCampo () {
        this.valor_evento = 0
        this.evento_transf_id = ''
        this.percent_transf = 0
        this.tipo_variacao_transf = '0'
        if (this.tipo_calculo === '2') {
          this.tipo_variacao = '0'
        }
      },
      limpaMeses () {
        if (this.tipo_vigencia === '0') {
          this.meses_vigencia = []
        }
      },
      limpaErro () {
        this.alertCodigo = false
      },
      eventosDisponiveis () {
        let eventos_disponiveis

        if (this.loadeventos) {
          eventos_disponiveis = this.loadeventos.filter(x1 => {
            let retorno = false
            if (x1.codigo !== this.codigo && x1.codigo.substring(4, 7) !== '000') {
              retorno = true
            }
            return retorno
          })
        } else {
          eventos_disponiveis = []
        }

        return eventos_disponiveis
      },
      async validaGrupo () {
        if (parseInt(this.codigo.substring(0, 3)) > 0 && this.codigo.length === 7 && this.codigo.substring(4, 7) !== '000') {
          const codigoTitulo = this.codigo.substring(0, 3) + '.000'
          const respostaApi = await api.getEntidade('evento/codigo', codigoTitulo)

          if (respostaApi.data[0].length <= 0) {
            this.alertCodigo = true
            this.snackbar = {
              show: true,
              message: 'Crie o evento titulo com código: ' + codigoTitulo + ' antes de criar este evento!',
            }
          }
        }

        return true
      },
    },
  }
</script>
<style lang="scss">

.v-input--selection-controls {
  margin-top: 5px !important;
  padding-top: 4px;
}

</style>
