import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Evento } from '@/models/Evento'
import Swal from 'sweetalert2'
import { eventoTransformer } from '@/utils/transformers'
import { currencyFormatter } from '@/utils/formatter'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    loading: true,
    isEditing: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit('SET_STATE', { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Evento() }, { root: true })
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('evento', id).then(response => {
        response.data.forEach(element => {
          element.tipo_evento = element.tipo_evento.toString()
          element.tipo_calculo = element.tipo_calculo.toString()
          element.tipo_variacao = element.tipo_variacao.toString()
          element.tipo_vigencia = element.tipo_vigencia.toString()
          element.tipo_ocorrencia = element.tipo_ocorrencia.toString()
          element.tipo_agregate = element.tipo_agregate.toString()
          element.tipo_lancamento = element.tipo_lancamento.toString()
          element.tipo_variacao_transf = element.tipo_variacao_transf.toString()
          if (element.meses_vigencia?.meses) {
            element.meses_vigencia = element.meses_vigencia.meses
          } else {
            element.meses_vigencia = []
          }
          if (element.tipo_calculo === '0') {
            element.valor_evento = currencyFormatter(element.valor_evento, 4)
          } else if (element.tipo_calculo === '1') {
            element.valor_evento = currencyFormatter(element.valor_evento, 2)
          }
        })
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new Evento(eventoTransformer(response.data[0])) }, { root: true })
      })
    } else {
      commit(SET_STATE, { loading: false, isEditing: false })
    }
  },

  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Cadastrando Evento!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data }
        form.valor_evento = parseFloat(form.valor_evento)
        form.meses_vigencia = { meses: form.meses_vigencia };
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'evento', form.id)
            : api.cadastrarEntidade(form, 'evento')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Evento atualizado com sucesso!'
                : 'Evento cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch('eventos/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/eventos`,
            })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
